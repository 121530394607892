
import { Bank, ChallengePaymentInfo, Invoice } from "@/interfaces/payment";
import { ChallengePrice, ChallengeShortInfo } from "@/interfaces/challenge";
import { Vue, Component } from "vue-property-decorator";
import InvoiceForm from "@/components/payments/Invoice.vue";
import TpayChallenges from "@/components/payments/TpayChallenges.vue";
import { get, post } from "@/api";

@Component({
  components: {
    InvoiceForm,
    TpayChallenges,
  },
})
export default class ChallengePayment extends Vue {
  wantInvoice = true;
  invoice!: Invoice;
  pickedBank!: Bank;
  challenge: any = this.$store.state.payment;
  relatedChallenges!: ChallengeShortInfo[];
  pickedLanguage = this.$store.state.payment.language;

  isUserPickingLang = false;
  isUserPickingCurr = false;
  pickedCurrency = this.challenge.currency
    ? this.challenge.currency
    : this.initialCurrency;
  price!: string;
  termsAccepted = false;
  showTermsWarning = false;

  created() {
    this.getRelatedChallenges();
    this.filterPriceByCurrency();
    this.checkPaymentAvailability();
  }

  async checkPaymentAvailability() {
    try {
      const { data } = await get.challenges(this.challenge.id);
      if (data.purchased) {
        this.$router.push("/payment/challenge/owned");
      }
      if (data.waiting) {
        this.$router.push("/payment/challenge/confirm");
      }
    } catch (error) {
      console.log(error);
    }
  }

  get initialCurrency(): string {
    let startingCurrency: string;
    switch (this.$i18n.locale) {
      case "pl":
        startingCurrency = "PLN";
        break;
      case "en-GB":
        startingCurrency = "GBP";
        break;
      case "en-US":
        startingCurrency = "USD";
        break;
      default:
        startingCurrency = "GBP";
    }
    const isAvailable = this.challenge.prices.find(
      (price: ChallengePrice) => price.currency == startingCurrency
    );
    if (isAvailable) {
      return startingCurrency;
    }
    return this.challenge.prices[0].currency;
  }

  updateInvoice(payload: Invoice) {
    this.invoice = payload;
  }

  prepareDataForPayment() {
    const payload = {
      challenge_id: this.challenge.id,
      currency: this.pickedCurrency,
      displayed_price: this.price,
    };
    if (this.pickedCurrency === "PLN") {
      Object.assign(payload, {
        bank_group: this.pickedBank.id,
      });
    }
    if (this.wantInvoice) {
      Object.assign(payload, {
        send_invoice: true,
        invoice_data: this.invoice,
      });
    }
    return payload;
  }

  proceedToPayment() {
    if (!this.canUserPay) {
      return;
    }
    if (!this.termsAccepted) {
      this.showTermsWarning = true;
      return;
    }
    try {
      this.PaymentRedirect();
    } catch (error) {
      this.$router.push("payment/checkout/failure");
    }
  }

  async PaymentRedirect() {
    const challenge_info = this.prepareDataForPayment();
    const {
      data: { redirection_link },
    } = await post.purchaseChallenge(challenge_info);
    window.location.href = redirection_link;
  }

  get isInvoiceValid(): boolean {
    if (this.wantInvoice) {
      const refs: any = this.$refs.invoice_form;
      return refs.validateFields();
    }
    return true;
  }

  async getRelatedChallenges() {
    try {
      const response = await get.getRelatedChallenges(
        this.$store.state.payment.id
      );
      this.relatedChallenges = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  get Languages() {
    return [
      ...new Set(this.relatedChallenges.map((challenge) => challenge.language)),
    ];
  }

  filterChallengesByLanguage() {
    this.challenge = this.relatedChallenges.find(
      (challenge) => challenge.language === this.pickedLanguage
    );
  }

  get Currencies() {
    return [
      ...new Set(
        this.challenge.prices.map((price: ChallengePrice) => price.currency)
      ),
    ];
  }

  filterPriceByCurrency() {
    this.price = this.challenge.prices.filter(
      (price: ChallengePrice) => price.currency === this.pickedCurrency
    )[0].price;
  }

  loadPickedBank(bank: Bank) {
    this.pickedBank = bank;
  }

  translateLanguage(langCode: string) {
    const langDict: any = this.$t("languages-translated");
    return langDict[langCode];
  }

  get canUserPay() {
    if (this.pickedCurrency == "PLN") {
      // Invoke validation
      this.isInvoiceValid;
      if (this.pickedBank && this.isInvoiceValid) {
        return true;
      }
    } else {
      if (this.isInvoiceValid) {
        return true;
      }
    }
    return false;
  }
}


import Vue from "@/interfaces/vue";
import { Bank } from "@/interfaces/payment";
import { Component } from "vue-property-decorator";
import { get } from "@/api";

@Component({})
export default class TpayOptions extends Vue {
  banks: Bank[] = [];
  picked_bankID = 0;
  loaded = false;

  mounted() {
    this.getTpayBanks();
  }

  async getTpayBanks() {
    try {
      const { data } = await get.calmsieBankGroups();
      this.banks = data;
      this.loaded = true;
    } catch (error) {
      console.log(error);
    }
  }

  emitPickedBank(bank: Bank) {
    this.picked_bankID = bank.id;
    this.$emit("picked-bank", bank);
  }
}

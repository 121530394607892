var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-container"},[_c('div',{staticClass:"details-challenge"},[_c('h2',[_vm._v(_vm._s(_vm.$t("payment.purchase-details")))]),_c('div',{staticClass:"challenge-detail"},[_c('span',{staticClass:"challenge-title"},[_vm._v(_vm._s(_vm.challenge.title))]),_c('div',{staticClass:"lang-picker picker",on:{"click":function () {
            _vm.isUserPickingLang = !_vm.isUserPickingLang;
            _vm.isUserPickingCurr = false;
          }}},[_c('span',[_vm._v(_vm._s(_vm.$t("challenges-overview.pick-lang")))]),_c('div',{staticClass:"picker-wrapper"},[_vm._v(" "+_vm._s(_vm.translateLanguage(_vm.pickedLanguage))+" "),_c('i',{staticClass:"far fa-chevron-down",class:{ on: _vm.isUserPickingLang }}),(_vm.isUserPickingLang)?_c('div',{staticClass:"languages"},_vm._l((_vm.Languages),function(lang){return _c('div',{key:lang,class:{ picked: _vm.pickedLanguage == lang },on:{"click":function($event){(_vm.pickedLanguage = lang),
                  _vm.filterChallengesByLanguage(),
                  _vm.filterPriceByCurrency()}}},[_vm._v(" "+_vm._s(_vm.translateLanguage(lang))+" ")])}),0):_vm._e()])]),_c('div',{staticClass:"currency-picker picker",on:{"click":function () {
            _vm.isUserPickingCurr = !_vm.isUserPickingCurr;
            _vm.isUserPickingLang = false;
          }}},[_c('span',[_vm._v(_vm._s(_vm.price))]),_c('div',{staticClass:"picker-wrapper"},[_vm._v(" "+_vm._s(_vm.pickedCurrency.toUpperCase())+" "),_c('i',{staticClass:"far fa-chevron-down",class:{ on: _vm.isUserPickingCurr }}),(_vm.isUserPickingCurr)?_c('div',{staticClass:"currencies"},_vm._l((_vm.Currencies),function(currency){return _c('div',{key:currency,class:{ picked: currency == _vm.pickedCurrency },on:{"click":function($event){(_vm.pickedCurrency = currency), _vm.filterPriceByCurrency()}}},[_vm._v(" "+_vm._s(currency)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"price-tag"},[_c('h4',[_vm._v(_vm._s(_vm.$t("payment.total-price")))]),_c('h4',[_vm._v(_vm._s(_vm.price + " " + _vm.pickedCurrency))])])]),_c('div',{staticClass:"payment-details"},[(_vm.pickedCurrency == 'PLN')?_c('h2',[_vm._v(_vm._s(_vm.$t("payment.payment-option")))]):_vm._e(),(_vm.pickedCurrency == 'PLN')?_c('div',{staticClass:"TPay"},[_c('TpayChallenges',{on:{"picked-bank":_vm.loadPickedBank}})],1):_vm._e(),_c('p',{staticClass:"company-name"},[_vm._v(" "+_vm._s(_vm.$t("payment.service-provider") + "Calmsie Sp. z o. o.")+" ")]),_c('div',{staticClass:"terms-check check",on:{"click":function($event){_vm.termsAccepted = !_vm.termsAccepted}}},[_c('img',{attrs:{"src":_vm.termsAccepted
            ? require("@/assets/checked.png")
            : require("@/assets/unchecked.png")}}),_c('div',{staticClass:"terms",class:{ warn: _vm.showTermsWarning }},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("payment.terms1"))+" "),_c('a',{attrs:{"target":"_blank","href":"https://calmsie.ai/termsandconditions?provider=Calmsie%20Sp.%20z%20o.o.&t=v&adress=Lublin%2020-060%20Bartosza%20Głowackiego%203%2F5%2F1&taxid=7123401355"}},[_vm._v(_vm._s(_vm.$t("payment.terms-conditions")))]),_vm._v(_vm._s(_vm.$t("payment.terms2"))+" ")])])]),_c('h2',[_vm._v(_vm._s(_vm.$t("payment.payment-information")))]),_c('div',{staticClass:"invoice-check check",on:{"click":function($event){_vm.wantInvoice = !_vm.wantInvoice}}},[_c('img',{attrs:{"src":_vm.wantInvoice
            ? require("@/assets/unchecked.png")
            : require("@/assets/checked.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("payment.i-dont-need-an-invoice")))])]),_c('transition',{attrs:{"name":"invoice"}},[(_vm.wantInvoice)?_c('InvoiceForm',{ref:"invoice_form",on:{"submit-form":_vm.updateInvoice}}):_vm._e()],1),_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("payment.form.step-back"))+" ")]),(_vm.pickedCurrency == 'PLN')?_c('button',{on:{"click":_vm.proceedToPayment}},[_vm._v(" "+_vm._s(_vm.$t("payment.form.confirm"))+" ")]):_c('button',{on:{"click":_vm.proceedToPayment}},[_vm._v(" "+_vm._s(_vm.$t("payment.form.next"))+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue, Prop } from "vue-property-decorator";
import vSelect from "vue-select";

@Component({
  components: {
    vSelect,
  },
})
export default class InvoiceForm extends Vue {
  @Prop({ default: false })
  isEmptyField!: boolean;

  user_make_action = false;
  country = "";

  invoice: Record<string, string> = {
    name: "",
    street: "",
    building: "",
    apartment: "",
    city: "",
    postal_code: "",
    email: "",
    tax_vat: "",
  };

  submitForm() {
    const payload = this.invoice;
    Object.assign(this.invoice, {
      country: this.countryCode,
    });
    this.$emit("submit-form", payload);
  }

  get validateForm() {
    const validation = {
      name: false,
      street: false,
      building: false,
      city: false,
      country: false,
      postal_code: false,
      email: this.validateEmail,
    };
    if (this.invoice.name === "") validation.name = false;
    else validation.name = true;

    if (this.invoice.street === "") validation.street = false;
    else validation.street = true;

    if (this.invoice.building === "") validation.building = false;
    else validation.building = true;

    if (this.invoice.city === "") validation.city = false;
    else validation.city = true;

    if (this.countryCode === "") validation.country = false;
    else validation.country = true;

    if (this.invoice.postal_code === "") validation.postal_code = false;
    else validation.postal_code = true;

    return validation;
  }

  get validateEmail(): boolean {
    const reg = new RegExp("\\S+@\\S+.\\S+");
    if (reg.test(this.invoice.email)) return true;
    return false;
  }

  validateFields(): boolean {
    // Function is invoked from outside
    this.user_make_action = true;
    return (
      this.validateForm.name &&
      this.validateForm.street &&
      this.validateForm.building &&
      this.validateForm.city &&
      this.validateForm.country &&
      this.validateForm.postal_code &&
      this.validateForm.email
    );
  }

  get countryCode(): string {
    // If user input exist in countries, return country code instead as payload
    if(this.country === null) {
      return ""
    }
    const capitalizedCountry: string = this.country.charAt(0).toUpperCase() + this.country.slice(1)
    if (this.countries[capitalizedCountry]) {
      return this.countries[capitalizedCountry];
    } else {
      return this.country
    }
  }

  countries: Record<string, string> = {
    Polska: "PL",
    Canada: "CA",
    USA: "US",
    "United Kingdom": "GB",
    Australia: "AU",
    China: "CN",
    Germany: "DE",
    Spain: "ES",
    France: "FR",
    India: "IN",
  };
}
